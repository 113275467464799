input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.react-datepicker-popper{
  z-index: 2 !important;
}
.additional-condition-bar .p-scrollpanel-bar {
  background-color: #a8a8a8 !important;
  opacity: 1 !important;
}

.autocomplete {
  padding: 8px;
  border: 1px solid #D1D5DBFF;
  border-radius: 5px;
}

.autocomplete:focus {
  outline: 2px solid #6366F1;
}